import html2pdf from "html2pdf.js"
import { useContext, useEffect, useMemo, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { StoreContext } from "src/lib/store"
export const generatePDF = async (htmlReceipt, afterPrint = () => {}) => {
  const printWindow = window.open(
    " ",
    "_blank",
    // "left=0,top=0,width=1000,height=auto,toolbar=0,scrollbars=0,status=0",
  )
  // printWindow.document.open()
  if (printWindow) {
    // Mở một trang con bên trong cửa sổ con
    // Sao chép tất cả các liên kết CSS từ document gốc
    const styles = Array.from(
      document.querySelectorAll('link[rel="stylesheet"], style'),
    )
      .map(style => style.outerHTML)
      .join("")

    // Mở một trang con bên trong cửa sổ con và thêm CSS vào head
    printWindow.document.write(`
      <html>
        <head>
          ${styles} <!-- Thêm tất cả các style từ cửa sổ cũ -->
        </head>
        <body>
          ${htmlReceipt}
        </body>
      </html>
    `)

    printWindow.document.close()
    // Gọi print khi popup đã load xong
    printWindow.onload = () => {
      printWindow.print()
    }

    printWindow.onafterprint = () => {
      printWindow.close() // Đóng sau khi in
      !!afterPrint && afterPrint()
    }
  } else {
    // alert("Trình chặn popup đã ngăn cản việc mở cửa sổ con.")
  }
}
const PrintPdfFromHtml = () => {
  const { pdfFromHtmlStore } = useContext(StoreContext)
  const [filePdfFromHtml, setFilePdfFromHtml] = pdfFromHtmlStore
  const divRef = useRef(null)
  const nav = useNavigate()
  const handlePrint = useReactToPrint({
    content: () => divRef.current,
    onBeforeGetContent: () =>
      new Promise(resolve => {
        const img = document.querySelector("img")
        if (img.complete) {
          resolve()
        } else {
          img.onload = () => resolve()
        }
      }),
    // onBeforeGetContent: () => {
    // // Phóng to cửa sổ in trước khi hiển thị
    // const originalStyle = document.body.style.overflow
    // document.body.style.overflow = "auto"
    // window.print()
    // document.body.style.overflow = originalStyle
    // },
  })
  const dowloadPdfFromHtml = namePdf => {
    let element = document.getElementById("dowload-receipt-id")
    let opt = {
      // margin: [15, 0, 15, 0],
      filename: namePdf || `phiếu báo thu.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        // dpi: 192,
        scale: 4,
        // letterRendering: true,
        // useCORS: true,
      },

      jsPDF: {
        unit: "mm",
        format: "letter",
        orientation: "portrait",
      },
      // jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }

    // New Promise-based usage:
    html2pdf().from(element).set(opt).save()
  }
  useEffect(() => {
    // isDowload: có tải về luôn không,
    // htmlReceipt: html của pdf,
    // namePdf: tên file pdf
    if (!!filePdfFromHtml?.htmlReceipt) {
      // if (!!filePdfFromHtml?.isPreview) {
      //   nav(ROUTER.PREVIEW_PDF, {
      //     state: {
      //       htmlPdf: filePdfFromHtml?.htmlReceipt,
      //     },
      //   })
      // } else
      if (!!filePdfFromHtml?.isDowload)
        dowloadPdfFromHtml(filePdfFromHtml?.namePdf)
      else if (!!filePdfFromHtml?.isNewWindow)
        generatePDF(filePdfFromHtml?.htmlReceipt)
      else handlePrint()
    }
  }, [filePdfFromHtml])
  return (
    <div style={{ display: "none" }}>
      {useMemo(
        () => (
          <div
            id="dowload-receipt-id"
            ref={divRef}
            dangerouslySetInnerHTML={{
              __html: filePdfFromHtml?.htmlReceipt,
            }}
            style={
              {
                // background: "#ccc",
                // width: "210mm" /* chiều rộng */,
                // height: "297mm" /* chiều cao */,
                // backgroundColor: "white" /* màu nền */,
                // margin: "0 auto" /* căn giữa */,
              }
            }
          />
        ),
        [filePdfFromHtml],
      )}
    </div>
  )
}

export default PrintPdfFromHtml

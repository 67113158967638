import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  searchSingleNow: "",
  searchOwnerNow: "",
  searchVBBH: "",
  DossierRelative: "",
  textSeachQuick: {},
  openModalSearchSingle: false,
}

export const singleSlice = createSlice({
  name: "single",
  initialState,
  reducers: {
    setSearchSingleNow: (state, action) => {
      state.searchSingleNow = action.payload
    },
    setSearchDossierRelative: (state, action) => {
      state.DossierRelative = action.payload
    },
    setSearchVBBH: (state, action) => {
      state.searchVBBH = action.payload
    },
    setSearchOwnerNow: (state, action) => {
      state.searchOwnerNow = action.payload
    },
    setOpenModalSearchSingle: (state, action) => {
      state.openModalSearchSingle = action.payload
    },
    setDiffModalSearchSingle: (state, action) => {
      state.openModalSearchSingle = !state.openModalSearchSingle
    },
    setSearchSingle: (state, action) => {
      state.textSeachQuick = {
        // ...state.textSeachQuick,
        ...action.payload,
      }
    },
  },
})

export const {
  setSearchSingleNow,
  setSearchSingle,
  setSearchVBBH,
  setSearchDossierRelative,
  setSearchOwnerNow,
  setOpenModalSearchSingle,
  setDiffModalSearchSingle,
} = singleSlice.actions

export default singleSlice.reducer
